import Button from '@atlaskit/button'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import Select from '@atlaskit/select'
import { useCallback, useEffect, useMemo, useState } from 'react'

import EmptyState, { ErrorEmptyState } from '../../../../components/EmptyState'
import PageHeader from '../../../../components/PageHeader'
import PopupButton from '../../../../components/PopupButton'
import Spinner from '../../../../components/Spinner'
import { useSkillset } from '../../../../components/skillset/utils'
import {
  useGetMatchingByIdQuery,
  useGetMeQuery,
  UserCategory,
  useUpsertMatchingProposalMutation,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import useValues from '../../../../lib/useValues'
import { getFlagEmoji } from '../Matching/utils'

import AssistantCard from './AssistantCard'
import CriteriaEditor from './CriteriaEditor'
import SettingsEditor, { useSettingsValues } from './SettingsEditor'
import {
  MachineList,
  MachineLoadingContainer,
  MachineOuter,
  MachineSection,
} from './styled'
import { MatcherAssistant } from './types'
import useAssistants, { ASSISTANTS_PER_PAGE } from './useAssistants'
import {
  DEFAULT_CRITERIA,
  DEFAULT_FILTER,
  getAssistantOrder,
  makeAssistantFilter,
  getReasonsForMatch,
  getCriteriaFromMatching,
} from './utils'

interface MatchingProps {
  matchingId: string
}

const ASSISTANT_COUNTRIES = ['CA', 'FR', 'US']
const ASSISTANT_COUNTRIES_OPTIONS = ASSISTANT_COUNTRIES.map((countryCode) => ({
  value: countryCode,
  label: getFlagEmoji(countryCode) + ' ' + countryCode,
}))

const Machine = ({ matchingId }: MatchingProps) => {
  const { data } = useGetMeQuery()
  const meCountryCode = data?.me?.city?.address?.countryCode
  const [didLoadAssistants, loadAssistants] = useSwitch(false)
  const [settings, { patch: patchSettings }] = useSettingsValues()
  const [criteria, { patch: patchCriteria, reset: resetCriteria }] =
    useValues(DEFAULT_CRITERIA)
  const [filter, { setters: filterSetters }] = useValues(DEFAULT_FILTER, [
    'search',
  ])

  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    meCountryCode === 'FR'
      ? ['FR']
      : meCountryCode === 'US'
      ? ['US', 'CA']
      : ASSISTANT_COUNTRIES,
  )

  const { skillset, skillNameById } = useSkillset()
  const assistantsQuery = useAssistants(
    {
      countryCodes:
        selectedCountries.length === 0 ||
        selectedCountries.sort() === ASSISTANT_COUNTRIES
          ? undefined
          : selectedCountries,
    },
    {
      skip: !didLoadAssistants,
    },
  )

  const matchingQuery = useGetMatchingByIdQuery({
    variables: {
      matchingId,
    },
  })

  const [upsertProposal, upsertMutation] = useUpsertMatchingProposalMutation()

  const matching = matchingQuery.data?.matching
  const isLoading =
    matchingQuery.loading || assistantsQuery.loading || upsertMutation.loading
  const assistants = useMemo(
    () =>
      isLoading
        ? []
        : assistantsQuery.data?.list?.items.filter(
            (assistant) =>
              criteria.showSandboxUsers ||
              assistant.category !== UserCategory.SANDBOX,
          ),
    [isLoading, assistantsQuery.data?.list?.items, criteria.showSandboxUsers],
  )
  const numberOfAssistantPagesLoaded = useMemo(
    () =>
      assistantsQuery.data?.list.items.length
        ? Math.floor(
            assistantsQuery.data?.list.items.length / ASSISTANTS_PER_PAGE,
          )
        : 0,
    [assistantsQuery.data?.list.items.length],
  )
  const error =
    matchingQuery.error || assistantsQuery.error || upsertMutation.error

  const queueAssistant = useCallback(
    (assistantId: string) => {
      const assistant = assistants?.find(({ id }) => id === assistantId)
      if (!assistant) {
        return
      }

      upsertProposal({
        variables: {
          input: {
            matchingId,
            assistantId,
            reasonForMatch: getReasonsForMatch(
              assistant,
              criteria,
              settings,
              skillNameById,
            ),
          },
        },
      })
    },
    [upsertProposal, skillNameById, assistants, criteria, settings, matchingId],
  )

  const { queued, others } = useMemo(() => {
    return (assistants || [])
      .filter(makeAssistantFilter(filter))
      .sort(
        (a, b) =>
          getAssistantOrder(a, criteria, settings) -
          getAssistantOrder(b, criteria, settings),
      )
      .reduce<{ queued: MatcherAssistant[]; others: MatcherAssistant[] }>(
        (all, assistant) => {
          if (
            matching?.proposals.some(
              ({ assistant: { id } }) => id === assistant.id,
            )
          ) {
            all.queued.push(assistant)
          } else {
            all.others.push(assistant)
          }
          return all
        },
        { queued: [], others: [] },
      )
  }, [assistants, filter, criteria, settings, matching?.proposals])

  useEffect(() => {
    if (matching?.profiles.length) {
      resetCriteria()
      patchCriteria(getCriteriaFromMatching(matching, skillset))
    }
  }, [matching, matching?.profiles, patchCriteria, resetCriteria, skillset])

  return (
    <MachineOuter>
      <PageHeader
        searchInputPlaceholder={'Filter by assistant name'}
        searchTerm={filter.search}
        onChangeSearchTerm={filterSetters.search}
        hasActiveFilter
        filtersContent={() => (
          <CriteriaEditor criteria={criteria} onPatch={patchCriteria} />
        )}
        actions={
          <PopupButton
            content={() => (
              <SettingsEditor values={settings} onPatch={patchSettings} />
            )}
            buttonProps={{
              iconAfter: <PreferencesIcon label={''} />,
            }}
          />
        }
      >
        {'Machine'}
      </PageHeader>

      {!!error && <ErrorEmptyState error={error} />}

      {!didLoadAssistants && (
        <>
          <EmptyState
            emoji={'🔍'}
            header={''}
            primaryAction={
              <Select
                inputId={'multi-select-countries'}
                className={'multi-select'}
                classNamePrefix={'react-select'}
                options={ASSISTANT_COUNTRIES_OPTIONS}
                onChange={(selected) =>
                  selected
                    ? setSelectedCountries(selected.map(({ value }) => value))
                    : setSelectedCountries([])
                }
                value={selectedCountries.map((countryCode) => ({
                  value: countryCode,
                  label: getFlagEmoji(countryCode) + ' ' + countryCode,
                }))}
                isMulti
                isSearchable={false}
                placeholder={'Choose a country'}
              />
            }
            tertiaryAction={
              <Button onClick={loadAssistants}>{'Load assistants'}</Button>
            }
          />
        </>
      )}

      {!!others.length && (
        <MachineList>
          {!!queued.length && <MachineSection>{'In queue'}</MachineSection>}
          {queued.map((assistant) => (
            <AssistantCard
              key={assistant.id}
              assistant={assistant}
              settings={settings}
              criteria={criteria}
              motivation={
                matching?.applications.find(
                  ({ assistant: { id } }) => assistant.id === id,
                )?.motivation
              }
            />
          ))}
          <MachineSection>{'All assistants'}</MachineSection>
          {others.map((assistant) => (
            <AssistantCard
              key={assistant.id}
              assistant={assistant}
              settings={settings}
              criteria={criteria}
              motivation={
                matching?.applications.find(
                  ({ assistant: { id } }) => assistant.id === id,
                )?.motivation
              }
              onQueue={queueAssistant}
            />
          ))}
        </MachineList>
      )}

      {isLoading && (
        <MachineLoadingContainer>
          <Spinner size={'large'} />

          {assistantsQuery.loading && (
            <div>
              {`Loading assistants (${numberOfAssistantPagesLoaded} pages loaded)...`}
            </div>
          )}
        </MachineLoadingContainer>
      )}
    </MachineOuter>
  )
}

export default Machine
