import Button from '@atlaskit/button'

import { formatDateTime } from '../../../pages/autopilot/utils'
import { LoadingSpinner } from '../../Spinner'

import { Outer } from './styled'

type Props = {
  name?: string | null
  delayUntil: string | Date
  onCancelScheduledPause?: () => void
  cancelScheduledPauseLoading?: boolean
}

const PendingPauseBanner = ({
  name = 'Unknown entity',
  delayUntil,
  onCancelScheduledPause,
  cancelScheduledPauseLoading,
}: Props) => {
  return (
    <Outer>
      <p>
        {`🤖 ${name} is scheduled for pause on `}
        <b>{formatDateTime(delayUntil)}</b>
      </p>
      <Button appearance={'danger'} onClick={onCancelScheduledPause}>
        {cancelScheduledPauseLoading ? (
          <LoadingSpinner />
        ) : (
          'Cancel Scheduled Pause'
        )}
      </Button>
    </Outer>
  )
}

export default PendingPauseBanner
