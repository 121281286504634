import { compact } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import Tabs from '../../../components/Tabs'
import PendingPauseBanner from '../../../components/billing/PendingPauseBanner'
import PendingChurnAutomationJob from '../../../components/churn/PendingChurnAutomationJob'
import {
  TeamStatus,
  useCancelScheduledTeamChurnMutation,
  useCancelScheduleTeamPauseMutation,
  useGetTeamQuery,
  useScheduleTeamChurnMutation,
} from '../../../graphql'
import AutomationJobsInline from '../../autopilot/Jobs/ListInline'
import { isPending } from '../../autopilot/Jobs/utils'

import Billing from './Billing'
import Header from './Header'
import Info from './Info'
import Reporting from './Reporting'
import Timeline from './Timeline'
import { Content, Outer, TabContent } from './styled'

const Team = () => {
  const {
    url,
    params: { teamId },
  } = useRouteMatch<{ teamId: string }>()
  const {
    data,
    loading: getTeamLoading,
    error,
    refetch,
  } = useGetTeamQuery({
    variables: { id: teamId },
    skip: !teamId,
  })

  const [scheduleTeamChurn, { loading: isSchedulingChurn }] =
    useScheduleTeamChurnMutation({
      onCompleted: () => {
        setTimeout(() => window.location.reload(), 2)
      },
    })

  const [cancelScheduledTeamChurn, { loading: cancelChurnLoading }] =
    useCancelScheduledTeamChurnMutation({
      onCompleted: () => {
        refetch()
      },
    })

  const onCancelScheduledChurn = useCallback(() => {
    cancelScheduledTeamChurn({
      variables: {
        input: {
          teamId,
        },
      },
    })
  }, [cancelScheduledTeamChurn, teamId])

  const mock = !data?.team
  const team = data?.team

  const isPendingChurn = useMemo(() => {
    return (
      team?.pendingChurnAutomationJob &&
      isPending(team.pendingChurnAutomationJob)
    )
  }, [team])

  const isPendingPause = useMemo(() => {
    return team?.status !== TeamStatus.PAUSED && team?.billingPauseStatus
  }, [team])

  // Cancel Pause Team

  const [cancelScheduledTeamPause, { loading: cancelScheduledPauseLoading }] =
    useCancelScheduleTeamPauseMutation()

  const onCancelScheduledPause = useCallback(() => {
    if (!team?.billingPauseStatus) return
    cancelScheduledTeamPause({
      variables: {
        input: {
          billingPauseStatusId: team?.billingPauseStatus?.id,
          teamId,
        },
      },
    })
  }, [cancelScheduledTeamPause, team?.billingPauseStatus, teamId])

  if (!team) {
    return null
  }

  const tabs = compact([
    {
      label: 'Info',
      content: (
        <TabContent>
          <Info team={team} />
        </TabContent>
      ),
    },
    // Billing & package
    {
      label: 'Billing',
      content: (
        <TabContent>
          <Billing teamId={team.id} teamStatus={team.status} />
        </TabContent>
      ),
    },
    {
      label: 'Reporting',
      content: (
        <TabContent>
          <Reporting teamId={team.id} />
        </TabContent>
      ),
    },
    {
      label: 'Timeline',
      content: (
        <TabContent>
          <Timeline teamId={teamId} />
        </TabContent>
      ),
    },
  ])

  const rightTabs = [
    {
      label: '🤖',
      slug: 'autopilot',
      content: (
        <TabContent>
          <AutomationJobsInline
            filter={{
              teamId,
            }}
          />
        </TabContent>
      ),
    },
  ]

  const loading = getTeamLoading || cancelChurnLoading || isSchedulingChurn

  return (
    <Outer>
      <Header
        team={team}
        skeleton={mock}
        isPendingChurn={isPendingChurn || false}
        scheduleTeamChurn={scheduleTeamChurn}
        isSchedulingChurn={isSchedulingChurn}
      />
      {isPendingChurn && (
        <PendingChurnAutomationJob
          name={team.name}
          delayUntil={team.pendingChurnAutomationJob?.delayUntil || ''}
          onCancelScheduledChurn={onCancelScheduledChurn}
        />
      )}
      {isPendingPause && (
        <PendingPauseBanner
          name={team.name}
          delayUntil={team.billingPauseStatus?.startAt || ''}
          onCancelScheduledPause={onCancelScheduledPause}
          cancelScheduledPauseLoading={cancelScheduledPauseLoading}
        />
      )}

      <Content>
        {error ? (
          <ErrorEmptyState error={error} />
        ) : (
          <Tabs leftTabs={tabs} rightTabs={rightTabs} basePath={url} />
        )}
        <LoadingSpinner show={loading} />
      </Content>
    </Outer>
  )
}

export default Team
