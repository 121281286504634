import { DatePicker } from '@atlaskit/datetime-picker'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Field, FieldsRow, Select } from '../../../components/form'
import { TeamSubscriptionType } from '../../../graphql'

import { ReportsQuery, isValidQuery, TIME_ZONE } from './utils'

const Outer = styled.div``

type Props = {
  query?: Partial<ReportsQuery>
  onChange: (newQuery: Partial<ReportsQuery>) => void
}

const BILLING_OPTIONS = [
  { label: 'Cockpit Invoicing', value: TeamSubscriptionType.COCKPIT },
  { label: 'Maxio', value: TeamSubscriptionType.MAXIO },
  { label: 'All', value: undefined },
]

const QueryEditor = ({ query, onChange }: Props) => {
  const onSetFrom = useCallback(
    (fromDateStr) => {
      onChange({
        ...query,
        from: fromDateStr
          ? DateTime.fromISO(`${fromDateStr}T00:00:00`, {
              zone: TIME_ZONE.iana,
            }).toJSDate()
          : undefined,
      })
    },
    [onChange, query],
  )

  const onSetTo = useCallback(
    (toDateStr) => {
      onChange({
        to: toDateStr
          ? DateTime.fromISO(`${toDateStr}T23:59:59`, {
              zone: TIME_ZONE.iana,
            }).toJSDate()
          : undefined,
      })
    },
    [onChange],
  )

  const onSetSubscriptionType = useCallback(
    (subscriptionType: TeamSubscriptionType) => {
      onChange({
        ...query,
        teamSubscriptionType:
          subscriptionType === null
            ? undefined
            : subscriptionType === TeamSubscriptionType.COCKPIT
            ? TeamSubscriptionType.COCKPIT
            : TeamSubscriptionType.MAXIO,
      })
    },
    [onChange, query],
  )

  return (
    <Outer>
      <FieldsRow>
        <Field label={`From (12:00am ${TIME_ZONE.abbr})`}>
          <DatePicker
            hideIcon
            value={
              query?.from
                ? DateTime.fromJSDate(query?.from)
                    .setZone(TIME_ZONE.iana)
                    .toISODate()
                : ''
            }
            onChange={onSetFrom}
            isInvalid={query?.from && query?.to && !isValidQuery(query)}
          />
        </Field>
        <Field label={`To (11:59pm ${TIME_ZONE.abbr})`}>
          <DatePicker
            hideIcon
            value={
              query?.to
                ? DateTime.fromJSDate(query?.to)
                    .setZone(TIME_ZONE.iana)
                    .toISODate()
                : ''
            }
            onChange={onSetTo}
            isInvalid={query?.from && query?.to && !isValidQuery(query)}
          />
        </Field>
      </FieldsRow>
      <FieldsRow>
        <Field label={'Customer Subscription Type'}>
          <Select
            value={BILLING_OPTIONS.find(
              (e) => e.value === query?.teamSubscriptionType,
            )}
            options={BILLING_OPTIONS}
            onChangeValue={onSetSubscriptionType}
          />
        </Field>
      </FieldsRow>
    </Outer>
  )
}

export default QueryEditor
