import React from 'react'

import { CockpitInvoicingCollectionMethod } from '../../graphql'
import { Select } from '../form'

const OPTIONS = [
  {
    value: CockpitInvoicingCollectionMethod.CHARGE_DEFAULT_CARD,
    label: 'Charge default card',
  },
  {
    value: CockpitInvoicingCollectionMethod.SEND_INVOICE,
    label: 'Send invoice',
  },
]

const InvoicingCollectionMethodSelect = ({
  value,
  ...props
}: Omit<React.ComponentProps<typeof Select>, 'options'>) => (
  <Select
    value={
      value ? OPTIONS.find(({ value: method }) => value === method) : undefined
    }
    isSearchable={false}
    isClearable={false}
    options={OPTIONS}
    isMulti={false}
    {...props}
  />
)

export default InvoicingCollectionMethodSelect
