import { CodeBlock } from '@atlaskit/code'
// @ts-ignore
import { Item as AkItem } from '@atlaskit/navigation-next'
import { colors } from '@atlaskit/theme'
import React, { Fragment, useCallback, useRef } from 'react'

import { Dl, Dt, Dd } from '../../../../components/DlDtDd'
import EmptyState, { ErrorEmptyState } from '../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../components/Spinner'
import TeamCell from '../../../../components/teams/Cell'
import { UserAvatarsGroup } from '../../../../components/users/Avatar'
import UserCell from '../../../../components/users/Cell'
import { useGetAutomationJobByIdQuery } from '../../../../graphql'
import { formatDateTime, getSectionedName } from '../../utils'
import { AutomationName, SectionName } from '../List/styled'

import ActionsMenu from './ActionsMenu'
import LogItem from './LogItem'
import { Outer, LogsList, LogsListDateTime, ActionsOuter } from './styled'

const ACTION_COLORS = Object.freeze([
  colors.T100,
  colors.R100,
  colors.G100,
  colors.Y100,
  colors.B100,
  colors.P100,
])

type Props = {
  automationJobId?: string
}

const AutomationJobDetail = ({ automationJobId }: Props) => {
  const actionColor = useRef<Record<string, string>>({})

  const getActionColor = useCallback(
    (actionId: string) => {
      if (!actionColor.current[actionId]) {
        actionColor.current[actionId] =
          ACTION_COLORS[
            Object.keys(actionColor.current).length % ACTION_COLORS.length
          ]
      }
      return actionColor.current[actionId]
    },
    [actionColor],
  )

  const { data, error, loading } = useGetAutomationJobByIdQuery({
    variables: {
      id: automationJobId || '',
    },
    skip: !automationJobId,
  })

  if (!automationJobId) {
    return null
  }

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const job = data?.automationJob

  if (!job) {
    return <EmptyState emoji={'👻'} header={'Automation job not found'} />
  }

  let logAt: Date
  const shouldPrintDateTime = (_at: Date | string): boolean => {
    const prevLogAt = logAt
    logAt = new Date(_at)

    return !prevLogAt || logAt > new Date(prevLogAt.getTime() + 60_000)
  }

  const { section, name } = getSectionedName(job.automation.name)

  return (
    <Outer>
      <ActionsOuter>
        <ActionsMenu job={job} />
      </ActionsOuter>
      <Dl dtWidth={'130px'}>
        <Dt>{'Automation'}</Dt>
        <Dd>
          <p>
            <SectionName>{section}</SectionName>
            <br />
            <AutomationName>{name}</AutomationName>
            <br />
            {'When: '}
            {job.automation.triggerOn.description}
          </p>
        </Dd>

        {!!job.workspace && (
          <>
            <Dt>{'Workspace'}</Dt>
            <Dd>
              <AkItem
                text={job.workspace.executives[0]?.profile.displayName}
                subText={job.workspace.assistants[0]?.profile.displayName}
                // @ts-ignore
                before={() => (
                  <UserAvatarsGroup
                    size={'small'}
                    maxCount={2}
                    users={[
                      ...job.workspace!.executives,
                      ...job.workspace!.assistants,
                    ]}
                  />
                )}
                spacing={'compact'}
              />
            </Dd>
          </>
        )}

        {!!(
          job.user &&
          job.user.id !== job.assistant?.id &&
          job.user.id !== job.executive?.id
        ) && (
          <>
            <Dt>{'User'}</Dt>
            <Dd>
              <UserCell user={job.user} />
            </Dd>
          </>
        )}

        {!!job.executive && (
          <>
            <Dt>{'Executive'}</Dt>
            <Dd>
              <UserCell user={job.executive} />
            </Dd>
          </>
        )}

        {!!job.assistant && (
          <>
            <Dt>{'Assistant'}</Dt>
            <Dd>
              <UserCell user={job.assistant} />
            </Dd>
          </>
        )}

        {!!job.team && (
          <>
            <Dt>{'Team'}</Dt>
            <Dd>
              <TeamCell team={job.team} />
            </Dd>
          </>
        )}

        <Dt>{'Created at'}</Dt>
        <Dd>{formatDateTime(job.createdAt)}</Dd>

        <Dt>{'Last updated at'}</Dt>
        <Dd>{formatDateTime(job.updatedAt)}</Dd>

        <Dt>{'Cancelled at'}</Dt>
        <Dd>{job.cancelledAt && formatDateTime(job.cancelledAt)}</Dd>

        <Dt>{'Delay until'}</Dt>
        <Dd>
          <strong>{formatDateTime(job.delayUntil)}</strong>
        </Dd>

        <Dt>{'Started at'}</Dt>
        <Dd>{job.startedAt && formatDateTime(job.startedAt)}</Dd>

        <Dt>{'Ended at'}</Dt>
        <Dd>
          <strong>{job.endedAt && formatDateTime(job.endedAt)}</strong>
        </Dd>

        <Dt>{'Reviewed by'}</Dt>
        <Dd>{job.reviewedBy && <UserCell user={job.reviewedBy} />}</Dd>

        <Dt>{'Reviewed at'}</Dt>
        <Dd>{job.reviewedAt && formatDateTime(job.reviewedAt)}</Dd>

        <Dt>{'Parameters'}</Dt>
        <Dd>
          <CodeBlock
            showLineNumbers={false}
            language={'json'}
            text={JSON.stringify(job.params, null, 2)}
          />
        </Dd>

        <Dt>{'Results'}</Dt>
        <Dd>
          <CodeBlock
            showLineNumbers={false}
            language={'json'}
            text={JSON.stringify(job.results, null, 2)}
          />
        </Dd>
      </Dl>

      <LogsList>
        {job.logs?.map((log, index) => (
          <Fragment key={`${log.at} ${index}`}>
            {shouldPrintDateTime(log.at) && (
              <LogsListDateTime>{formatDateTime(log.at)}</LogsListDateTime>
            )}
            <LogItem log={log} getActionColor={getActionColor} />
          </Fragment>
        ))}
      </LogsList>
    </Outer>
  )
}

export default AutomationJobDetail
