import React from 'react'

import { Select } from '../form'

const OPTIONS = [
  {
    value: 'USD',
    label: 'US Dollar - USD',
  },
  {
    value: 'EUR',
    label: 'Euro - EUR',
  },
]

const InvoicingCurrencySelect = ({
  value,
  ...props
}: Omit<React.ComponentProps<typeof Select>, 'options'>) => (
  <Select
    value={
      value ? OPTIONS.find(({ value: method }) => value === method) : undefined
    }
    isSearchable={false}
    isClearable={false}
    options={OPTIONS}
    isMulti={false}
    {...props}
  />
)

export default InvoicingCurrencySelect
