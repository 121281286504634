import Lozenge from '@atlaskit/lozenge'
import {
  colors,
  typography,
  borderRadius,
  elevation,
  layers,
} from '@atlaskit/theme'
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip'
import React from 'react'
import styled, { css } from 'styled-components'

import {
  CockpitInvoicingCollectionMethod,
  StripeCustomerPaymentStatus,
  StripeInvoiceStatus,
} from '../../../../graphql'
import { ReportSummary, InvoiceEdit, Exception } from '../utils'

const BASE_HEIGHT = 52 // including header and padding
const ITEM_HEIGHT = 35
const PACKAGE_BASE_HEIGHT = 44
const TOGGL_USAGE_HEIGHT = 47

export const getInvoiceHeight = (
  { packages, items }: ReportSummary,
  edit?: InvoiceEdit | null,
): number =>
  BASE_HEIGHT +
  ITEM_HEIGHT * (items.length || 0) +
  ITEM_HEIGHT * (edit?.additionalItems?.length || 0) +
  packages.reduce(
    (height, { togglUsages }) =>
      height + PACKAGE_BASE_HEIGHT + TOGGL_USAGE_HEIGHT * togglUsages.length,
    0,
  )

export const ListOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 3;
  color: ${colors.text()};
`

export const ListInner = styled.div`
  flex: 1 1 auto;
`

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: solid 2px ${colors.backgroundHover};
  z-index: 4;
  padding-top: 32px;
`

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionTitle = styled.span`
  ${typography.h600()}
  padding: 0 8px;
  margin: 0;
  flex: 1;
`

export const InvoiceOuter = styled.div<{ selected?: boolean; isOdd?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  ${({ isOdd }) =>
    isOdd &&
    css`
      background-color: #fcfcfc;
    `}

  ${({ selected }) =>
    !selected &&
    css`
      & > div > * {
        opacity: 0.4;
      }
    `}

  & + & {
    border-top: solid 2px ${colors.backgroundHover()};
  }
`

export const InvoiceHeader = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    flex: 1;
  }
`

export const InvoiceBody = styled.div`
  flex: 1;
  display: flex;
`

export const InvoiceContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const PackageOuter = styled.div<{ faded?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 16px;
  padding-top: 4px;
  padding-left: 8px;
  border-left: 2px solid #e9e9e9;
  ${({ faded }) =>
    faded &&
    css`
      & > div > * {
        opacity: 0.4;
      }
    `}
`

export const PackageHeader = styled.div`
  display: flex;
`

export const PackageName = styled.div`
  flex: 1;
  ${typography.h300()};
  margin: 0;
  padding: 0;
`

export const TogglUsage = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    flex: 1;
  }
`

export const NumberCell = styled.div<{ bold?: boolean; faded?: boolean }>`
  text-align: right;
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.4;
    `}
`

export const HoursCell = styled(NumberCell)`
  flex: 0 0 110px;
`

export const AmountCell = styled(NumberCell)<{ highlight?: boolean }>`
  flex: 0 0 110px;
  ${({ highlight }) =>
    highlight &&
    css`
      color: ${colors.purple};
    `}
`

export const PricingCell = styled.div`
  flex: 1 0 110px;
  text-align: center;
`

export const TooltipInner = styled(TooltipPrimitive)`
  ${elevation.e200()};
  border-radius: ${borderRadius}px;
  z-index: ${layers.tooltip()};
  background-color: ${colors.heading};
  color: white;
  padding: 4px 8px;
  font-size: 12px;
`

export const Monospaced = styled.p`
  font-family: monospace;
  white-space: pre;
`

export const CheckboxCell = styled.div`
  margin-left: 24px;
  width: 24px;
  opacity: 1 !important;
  visibility: visible;
`

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`

export const TableSection = styled(TableRow)`
  position: sticky;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: solid 2px ${colors.backgroundHover};
  z-index: 4;
  padding-top: 32px;
`

export const MoreMenuCell = styled.div`
  width: 24px;
  visibility: hidden;
  opacity: 1 !important;

  ${InvoiceOuter}:hover &,
  ${TableSection} & {
    visibility: visible;
  }
`

export const CheckboxOuter = styled.div`
  flex: 0 0 24px;
  margin-left: 32px;
  opacity: 1 !important;
`

export const ItemRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px 16px;
  padding: 4px 0 4px 8px;
  border-left: 2px solid #e9e9e9;
`

export const ItemDescriptionCell = styled.div`
  flex: 1;
`

export const ItemLabel = styled.span`
  ${typography.h300()};
  margin-right: 8px;
`

export const PaymentStatusCell = styled.div`
  width: 100px;
  text-align: center;
`

export const PaymentStatusBadge = ({
  status,
}: {
  status: StripeCustomerPaymentStatus | CockpitInvoicingCollectionMethod
}) => {
  const boldStatuses: (
    | StripeCustomerPaymentStatus
    | CockpitInvoicingCollectionMethod
  )[] = [StripeCustomerPaymentStatus.REJECTED]
  return (
    <Lozenge
      children={status.replace('_', ' ')}
      isBold={boldStatuses.includes(status)}
      appearance={
        status === StripeCustomerPaymentStatus.CARD
          ? 'success'
          : status === StripeCustomerPaymentStatus.REJECTED
          ? 'removed'
          : status === StripeCustomerPaymentStatus.MANUAL
          ? 'removed'
          : status === StripeCustomerPaymentStatus.MISSING
          ? 'new'
          : 'default'
      }
    />
  )
}

export const ExceptionCell = styled.div`
  width: 100px;
  text-align: center;
`

export const ExceptionBadge = ({
  exception,
  churnedAt,
}: {
  exception?: Exception
  churnedAt?: Date | null
}) =>
  !exception ? null : (
    <Tooltip
      content={churnedAt?.toISOString().split('T')[0]}
      component={TooltipInner}
      position={'top'}
    >
      <Lozenge
        children={exception}
        // isBold={[].includes(exception)}
        appearance={
          exception === Exception.PAUSED
            ? 'new'
            : exception === Exception.CHURNED_NO_GRACE
            ? 'removed'
            : exception === Exception.CHURNED_WITHIN_GRACE
            ? 'removed'
            : exception === Exception.NO_ASSISTANT
            ? 'new'
            : exception === Exception.TRANSITIONING
            ? 'new'
            : 'default'
        }
      />
    </Tooltip>
  )

export const ItemBadge = ({ badge }: { badge: Exception | undefined }) =>
  !badge ? null : (
    <Lozenge
      children={badge}
      appearance={badge === Exception.REFUND ? 'new' : 'default'}
    />
  )

export const StartDateCell = styled.div<{ bold?: boolean }>`
  width: 100px;
  text-align: center;
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`

export const SuccessManagerCell = styled.div`
  width: 50px;
`

export const InvoiceStatusCell = styled.div`
  width: 100px;
  text-align: center;
`

export const InvoiceStatusBadge = ({
  status,
}: {
  status: StripeInvoiceStatus
}) => (
  <Lozenge
    children={status}
    isBold={[
      StripeInvoiceStatus.UNCOLLECTIBLE,
      StripeInvoiceStatus.OPEN,
    ].includes(status)}
    appearance={
      status === StripeInvoiceStatus.PAID
        ? 'success'
        : status === StripeInvoiceStatus.UNCOLLECTIBLE
        ? 'removed'
        : status === StripeInvoiceStatus.DRAFT
        ? 'new'
        : status === StripeInvoiceStatus.OPEN
        ? 'inprogress'
        : status === StripeInvoiceStatus.VOID
        ? 'removed'
        : status === StripeInvoiceStatus.DELETED
        ? 'removed'
        : 'default'
    }
  />
)

export const InvoiceLinesTable = styled.table`
  border: 1px solid white;
  margin-bottom: 8px;

  tr td + td {
    padding: 0 4px;
    border-left: 1px solid white;
  }

  tr:last-child td {
    border-top: 1px solid white;
  }
`

export const InvoiceDescription = styled.p`
  max-width: 500px;
  overflow: hidden;
  white-space: pre-wrap;
`

export const ModalOuter = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  padding: 16px;
  align-items: center;
  gap: 16px;
`
