import SectionMessage from '@atlaskit/section-message'
import React, { useCallback, useState } from 'react'

import Modal from '../../../../components/Modal'
import TeamSelect from '../../../../components/teams/Select'
import {
  useGetTeamWithPrincipalAndWorkspacesQuery,
  useUpdateWorkspaceTeamMutation,
  WorkspaceWithTeamFragment,
} from '../../../../graphql'

type Props = {
  workspace: WorkspaceWithTeamFragment
  onClose: () => void
}

const UpdateWorkspaceTeamModal = ({ workspace, onClose }: Props) => {
  const [teamId, setTeamId] = useState(workspace.team?.id)

  const { data, loading: getTeamLoading } =
    useGetTeamWithPrincipalAndWorkspacesQuery({
      variables: {
        id: workspace.team?.id || 'DUMMY_ID',
      },
      skip: !workspace.team?.id,
    })
  const team = data?.team

  const [
    updateWorkspaceTeam,
    { loading: updateWorkspaceTeamLoading, error: updateWorkspaceTeamError },
  ] = useUpdateWorkspaceTeamMutation({
    onCompleted: () => onClose(),
  })

  const saveClicked = useCallback(() => {
    if (!teamId) return

    let archiveTeamConfirmed = false

    if (
      team?.principalUser.id === workspace.executives[0].id &&
      team.workspaces.filter((w) => w.id !== workspace.id).length === 0
    ) {
      // moving principal user workspace and no other teammate workspaces
      // confirm that the team will be archived
      archiveTeamConfirmed = window.confirm(
        'This will archive the team because the principal user will be removed from the team.\n\nPlease confirm.',
      )
      if (!archiveTeamConfirmed) {
        return
      }
    }

    updateWorkspaceTeam({
      variables: {
        input: {
          workspaceId: workspace.id,
          teamId,
          archiveTeamConfirmed,
        },
      },
    })
  }, [
    team?.principalUser.id,
    team?.workspaces,
    teamId,
    updateWorkspaceTeam,
    workspace.executives,
    workspace.id,
  ])

  const loading = getTeamLoading || updateWorkspaceTeamLoading

  return (
    <Modal
      isOpen
      heading={'Update the team for this workspace'}
      onClose={onClose}
      actions={[
        {
          text: 'Save',
          isDisabled: !teamId || workspace.team?.id === teamId || loading,
          onClick: saveClicked,
        },
      ]}
    >
      <TeamSelect
        value={teamId ? { id: teamId } : undefined}
        onChange={(team) => team && setTeamId(team.id)}
        maxMenuHeight={250}
      />
      {/* 💩 For the select suggestions list to be visible */}
      <div style={{ height: 180 }}>
        {updateWorkspaceTeamError && (
          <SectionMessage
            title={'Workspace team update failed'}
            appearance={'error'}
          >
            <p>{updateWorkspaceTeamError.message}</p>
          </SectionMessage>
        )}
      </div>
      <SectionMessage
        title={'Changing the team will affect billing'}
        appearance={'information'}
      >
        {
          'This workspace’s time entries from the current billing cycle will be associated with the new team’s plan.'
        }
      </SectionMessage>
    </Modal>
  )
}

export default UpdateWorkspaceTeamModal
