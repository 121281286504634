import { Radio } from '@atlaskit/radio'
import React, { useEffect, useState } from 'react'

import CefrScaleSelect from '../../../../components/CefrScaleSelect'
import CitySelect from '../../../../components/CitySelect'
import IndustriesSelect from '../../../../components/IndustriesSelect'
import PersonalityTraitsSelect from '../../../../components/PersonalityTraitsSelect'
import TimeZoneSelect from '../../../../components/TimeZoneSelect'
import Toggle from '../../../../components/Toggle'
import ToolsSelect from '../../../../components/ToolsSelect'
import { Field, TextField } from '../../../../components/form'
import { LanguagesSelect } from '../../../../components/languages/select'
import { CityFragment } from '../../../../graphql'

import SkillsetSelect from './SkillsetSelect'
import { CriteriaEditorFieldsRow } from './styled'
import { Criteria } from './types'
import { getHoursRange } from './utils'

type Props = {
  criteria: Criteria
  onPatch: (newValues: Partial<Criteria>) => void
}

const CriteriaEditor = ({ criteria, onPatch }: Props) => {
  const [city, setCity] = useState<CityFragment | null>(null)

  // Clear city if TZ is set
  useEffect(() => {
    if (criteria.timeZone && city && city.timeZone !== criteria.timeZone) {
      setCity(null)
    }
  }, [city, criteria.timeZone])

  return (
    <>
      {!criteria.assistant && (
        <>
          <Field label={'City'}>
            <CitySelect
              value={city}
              onChangeValue={(city) => [
                setCity(city || null),
                city && onPatch({ timeZone: city.timeZone }),
              ]}
              isClearable
            />
          </Field>

          <Field label={'Time Zone'}>
            <TimeZoneSelect
              value={criteria.timeZone || null}
              // @ts-ignore
              onChangeValue={(timeZone: string | null) =>
                onPatch({ timeZone: timeZone || null })
              }
              isClearable
              isMulti={false}
            />
          </Field>

          <Field label={'Languages'}>
            <LanguagesSelect
              values={criteria.languages}
              onChangeValues={(languages) => onPatch({ languages })}
            />
          </Field>

          <Field label={'English level (at least)'}>
            <CefrScaleSelect
              values={criteria.englishLevel}
              onChangeValue={(englishLevel) => onPatch({ englishLevel })}
              isClearable
            />
          </Field>

          <Field label={'Hours needed'}>
            <TextField
              placeholder={'10 - 20'}
              value={
                criteria.hoursRange
                  ? `${criteria.hoursRange.from} - ${criteria.hoursRange.to}`
                  : ''
              }
              onChangeValue={(hours) =>
                onPatch({ hoursRange: getHoursRange(hours) })
              }
            />
          </Field>

          <Field label={'Industries'}>
            <IndustriesSelect
              isCreatable
              values={criteria.industries}
              onChangeValues={(industries) => onPatch({ industries })}
            />
          </Field>

          <Field label={'Tools'}>
            <ToolsSelect
              isCreatable
              values={criteria.tools}
              onChangeValues={(tools) => onPatch({ tools })}
            />
          </Field>

          <Field label={'Skillset'}>
            <SkillsetSelect
              values={criteria.skillset}
              onChangeValues={(skillset) => onPatch({ skillset })}
            />
          </Field>

          <Field label={'EA Personality'}>
            <PersonalityTraitsSelect
              isCreatable
              values={criteria.personalityTraits}
              onChangeValues={(personalityTraits) =>
                onPatch({ personalityTraits })
              }
            />
          </Field>

          <CriteriaEditorFieldsRow>
            <Field label={'Looking for'}>
              <Radio
                value={'match'}
                label={'Match'}
                name={'match-support'}
                isChecked={criteria.isMatch}
                onChange={() => onPatch({ isMatch: true, isSupport: false })}
              />
              <Radio
                value={'support'}
                label={'Support'}
                name={'match-support'}
                isChecked={criteria.isSupport}
                onChange={() => onPatch({ isMatch: false, isSupport: true })}
              />
            </Field>
            <Field label={'Show sandbox users'}>
              <Toggle
                isChecked={criteria.showSandboxUsers}
                onChange={() =>
                  onPatch({ showSandboxUsers: !criteria.showSandboxUsers })
                }
              />
            </Field>
          </CriteriaEditorFieldsRow>
        </>
      )}
    </>
  )
}

export default CriteriaEditor
