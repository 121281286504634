import Button from '@atlaskit/button'
import SectionMessage from '@atlaskit/section-message'
import { useMemo } from 'react'

import {
  BillingPauseStatus,
  SubscriptionFragment,
  SubscriptionState,
  TeamStatus,
  TeamSubscriptionType,
} from '../../graphql'
import useSwitch from '../../lib/useSwitch'

import PauseUnpauseTeamModal from './PauseUnpauseTeamModal'
import { Link, SectionMessageOuter } from './styled'
import { formatCycleDates } from './utils'

type Props = {
  teamId: string
  teamStatus: TeamStatus
  subscription?: SubscriptionFragment | null
  billingPauseStatus?: BillingPauseStatus | null
}

const PAUSE_NOTION_GUIDELINES_URL =
  'https://www.notion.so/withdouble/Client-Account-Pause-Guidelines-3a762dc81c9f44608c186a834e4e3d3a?pvs=4'

const SHOW_PAUSE_FEATURES = false // Todo T-8186: show pause features when we complete pause related automations

const TeamStateBanner = ({
  teamId,
  teamStatus,
  subscription,
  billingPauseStatus,
}: Props) => {
  const { type, state } = subscription || {}

  const [isPauseModalOpen, , closePauseModal, togglePauseModal] = useSwitch()

  const hasMaxioSubscription = type === TeamSubscriptionType.MAXIO
  const isMaxioActive =
    hasMaxioSubscription && subscription?.state === SubscriptionState.active
  const isMaxioPaused =
    hasMaxioSubscription && state === SubscriptionState.paused
  // Only Active Maxio Subscriptions can be paused currently, Cockpit pause is managed on the user level
  const showPause = useMemo(() => {
    if (!state || state === SubscriptionState.canceled) return false
    return isMaxioActive
  }, [isMaxioActive, state])

  const teamStatusPaused = teamStatus === TeamStatus.PAUSED
  const inBadMaxioState =
    (isMaxioActive && teamStatusPaused) || (isMaxioPaused && !teamStatusPaused)
  const ableToPauseUnpause = showPause || teamStatusPaused

  const isPendingPause = useMemo(() => {
    return teamStatus !== TeamStatus.PAUSED && billingPauseStatus
  }, [billingPauseStatus, teamStatus])

  return (
    <SectionMessageOuter>
      <SectionMessage
        appearance={
          inBadMaxioState
            ? 'error'
            : !isMaxioActive || teamStatusPaused
            ? 'information'
            : 'success'
        }
        title={
          (hasMaxioSubscription
            ? 'Maxio Subscription'
            : 'Cockpit Billing (on principal user)') +
          ` is ${
            state ? state.replaceAll('_', ' ').toUpperCase() : 'not set'
          }` +
          (inBadMaxioState ? ' but the account is set to paused' : '')
        }
      >
        {SHOW_PAUSE_FEATURES && subscription && ableToPauseUnpause && (
          <>
            <p>
              {'Please review the '}
              <Link
                href={PAUSE_NOTION_GUIDELINES_URL}
                target={'_blank'}
                rel={'noreferrer'}
              >
                {'Client Account Pause Guidelines'}
              </Link>
              {' before proceeding as pausing affects billing.'}
            </p>

            {billingPauseStatus && (
              <p>{`Billing is paused from ${formatCycleDates(
                billingPauseStatus?.startAt,
                billingPauseStatus?.endAt,
              )}`}</p>
            )}
            {!isPendingPause && (
              <>
                <Button onClick={togglePauseModal}>
                  {teamStatusPaused ? 'Unpause Team' : 'Pause Team'}
                </Button>
                <PauseUnpauseTeamModal
                  isOpen={isPauseModalOpen}
                  isPaused={teamStatusPaused}
                  teamId={teamId}
                  onClose={closePauseModal}
                  subscription={subscription}
                />
              </>
            )}
          </>
        )}
      </SectionMessage>
    </SectionMessageOuter>
  )
}

export default TeamStateBanner
