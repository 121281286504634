import Tag from '@atlaskit/tag'
import { DateTime } from 'luxon'
import React, { Fragment, useMemo } from 'react'

import { AirtableLinkButton } from '../../../../components/AirtableButton'
import { Dl, Dt, Dd } from '../../../../components/DlDtDd'
import LinkedInButton from '../../../../components/LinkedInButton'
import { renderTextWithLineBreaks } from '../../../../components/utils'
import { AIRTABLE_LEADS_TABLE_ID } from '../../../../constants'
import {
  Matching_TeamInviteFragment,
  MatchingProfileFragment,
  Maybe,
  MatchingProfile_PrincipalFragment,
  ProductFragment,
} from '../../../../graphql'
import getCurrencySymbol from '../../../../lib/getCurrencySymbol'
import { getCountryColor } from '../utils'

function renderText(text?: string | null) {
  if (!text) {
    return text
  }
  return renderTextWithLineBreaks(text.replace(/\\-/g, '-'))
}

interface Props {
  profile: MatchingProfileFragment
  withDisplayName?: boolean
  isNarrow?: boolean
  teamInvite?: Maybe<Matching_TeamInviteFragment>
  principalProfile?: Maybe<MatchingProfile_PrincipalFragment>
  product?: Maybe<ProductFragment>
  isOnMaxioBilling?: boolean
}

const Detail = ({
  profile,
  withDisplayName,
  isNarrow,
  teamInvite,
  principalProfile,
  product,
  isOnMaxioBilling,
}: Props) => {
  const basePrice = product?.basePrice || 0
  const sowDailyRate = useMemo(() => {
    if (!basePrice) return 'Unknown'
    return ((basePrice * 0.5) / 30.5).toFixed(2)
  }, [basePrice])
  const biweeklyRate = useMemo(() => {
    if (!basePrice) return 'Unknown'
    return (Number(sowDailyRate) * 14).toFixed(2)
  }, [basePrice, sowDailyRate])

  const currencySymbol = getCurrencySymbol(product?.currency || '')

  // USD is set, there are different rates for EUR and can be implemented later
  const showSOWRates =
    isOnMaxioBilling && basePrice && product?.currency === 'USD'

  return (
    <>
      {!!withDisplayName && <h2>{profile.name}</h2>}

      <Dl dtWidth={'160px'} singleColumn={!!isNarrow}>
        <Dt>{'Profiles'}</Dt>
        <Dd>
          {profile.linkedInUrl && (
            <LinkedInButton href={profile.linkedInUrl} target={'_blank'} />
          )}
          {!!profile.airtableLeadId && (
            <AirtableLinkButton
              tableId={AIRTABLE_LEADS_TABLE_ID}
              viewId={'viw2eRewKXaLXvUu9'}
              recordId={profile.airtableLeadId}
            >
              {'Edit'}
            </AirtableLinkButton>
          )}
        </Dd>

        <Dt>{'Intro Call Date'}</Dt>
        <Dd>
          {!!profile.introCallDate &&
            DateTime.fromISO(profile.introCallDate).toLocaleString(
              DateTime.DATE_MED,
            )}
        </Dd>

        <Dt>{'Internal Matching Date'}</Dt>
        <Dd>
          {!!profile.internalMatchingDate &&
            DateTime.fromISO(profile.internalMatchingDate).toLocaleString(
              DateTime.DATE_MED,
            )}
        </Dd>

        <Dt>{'Match Due Date'}</Dt>
        <Dd>
          {!!profile.matchDueDate &&
            DateTime.fromISO(profile.matchDueDate).toLocaleString(
              DateTime.DATE_MED,
            )}
        </Dd>

        <Dt>{'Location'}</Dt>
        <Dd>
          {profile.city}
          {!!profile.country && (
            <Tag
              text={profile.country}
              isRemovable={false}
              color={getCountryColor(profile.country)}
            />
          )}
        </Dd>

        <Dt>{'Role'}</Dt>
        <Dd>
          {profile.jobRole}
          {!!(profile.companyIndustry || profile.companySize) &&
            [
              ' @ ',
              profile.companyIndustry,
              profile.companySize ? ` (${profile.companySize} people)` : '',
            ].join('')}
        </Dd>

        <Dt>{'Desired Availability & Response Time'}</Dt>
        <Dd>
          {profile.desiredAvailability
            ? profile.desiredAvailability
            : '🚨 Not specified'}
        </Dd>

        <Dt>{'Product Info'}</Dt>
        <Dd>
          {`${product?.name} - ${product?.includedHours} hours`}

          <Tag
            text={isOnMaxioBilling ? 'Maxio' : 'Legacy'}
            isRemovable={false}
            color={isOnMaxioBilling ? 'green' : 'grey'}
          />
        </Dd>

        {showSOWRates && (
          <>
            <Dt>{'Base Price'}</Dt>
            <Dd>
              {currencySymbol}
              {basePrice}
            </Dd>
            <Dt>{'50% SOW Daily Rate*'}</Dt>
            <Dd>
              {currencySymbol}
              {sowDailyRate}
            </Dd>
            <Dt>{'50% SOW Bi-weekly Rate*'}</Dt>
            <Dd>
              {currencySymbol}
              {biweeklyRate}
            </Dd>
            <Dt>{'SOW Notes'}</Dt>
            <Dd>
              {
                'This rate is based on the US 50% SOW rate. For other currencies, please check the SOW rate in Notion. Or if the percentage is different, please adjust the rate accordingly.'
              }
            </Dd>
          </>
        )}

        {profile.bioSections?.map(({ title, text }, index) => (
          <Fragment key={index}>
            <Dt>{title}</Dt>
            <Dd>{renderText(text)}</Dd>
          </Fragment>
        ))}

        {teamInvite && (
          <>
            {<h3>{`Team Invite Details`}</h3>}
            <Dt>{'Hours Limit'}</Dt>
            <Dd>{teamInvite.hoursLimit || 'No Limit'}</Dd>
            <Dt>{'Includes Personal Tasks'}</Dt>
            <Dd>{teamInvite.isAllowedPersonal ? 'Yes' : 'No'}</Dd>
          </>
        )}

        {principalProfile && (
          <>
            {<h3>{`${principalProfile.name} (Team Lead)`}</h3>}
            <Dt>{'Profile'}</Dt>
            <Dd>
              {!!principalProfile.airtableLeadId && (
                <AirtableLinkButton
                  tableId={AIRTABLE_LEADS_TABLE_ID}
                  viewId={'viw2eRewKXaLXvUu9'}
                  recordId={principalProfile.airtableLeadId}
                >
                  {'Edit'}
                </AirtableLinkButton>
              )}
            </Dd>
          </>
        )}
      </Dl>
    </>
  )
}

export default Detail
