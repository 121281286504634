const getCurrencySymbol = (currency: string): string =>
  currency === 'USD'
    ? '$'
    : currency === 'CAD'
    ? '$'
    : currency === 'EUR'
    ? '€'
    : currency === 'GBP'
    ? '£'
    : ''

export default getCurrencySymbol
