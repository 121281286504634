import { borderRadius, colors, typography } from '@atlaskit/theme'
import { transparentize } from 'polished'
import { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

import _EmptyState from '../../../../components/EmptyState'

export const MachineOuter = styled.div<ComponentProps<'div'>>`
  display: flex;
  flex: 3;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  min-width: 300px;
`

export const MachineList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  flex: 1;
  align-items: baseline;
`

export const MachineSection = styled.h3`
  flex: 0 0 100%;
  ${typography.h600};
`

export const MachineLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${() => transparentize(0.7, '#fff')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

export const Card = styled.div<{ faded?: boolean; isExpanded?: boolean }>`
  margin: 8px;
  border: solid 2px ${colors.backgroundHover};
  border-radius: ${borderRadius}px;
  flex: 1 0 280px;
  max-width: 400px;
  opacity: ${({ faded }) => (faded ? '0.6' : '1')};
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  ${({ isExpanded }) =>
    !!isExpanded &&
    css`
      max-width: 100%;
    `}

  &:hover {
    opacity: 1;
  }
`

export const Profile = styled.div<{ isExpanded?: boolean }>`
  padding: 8px 0px 0px;
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  min-width: 280px;
  max-width: 600px;

  ${({ isExpanded }) =>
    !!isExpanded &&
    css`
      & div > p,
      & div > a p {
        line-height: 1.3em;
        font-size: 0.9em;
      }
    `}
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  flex: 0;
`

export const Attributes = styled.div`
  overflow: auto;
  padding: 0 8px 16px;
`

export const Attribute = styled.p<{ emoji: string }>`
  color: ${colors.subtleText};
  font-size: 0.8em;
  position: relative;

  margin-left: 12px;
  padding-left: 32px;
  margin-top: 8px;

  &:before {
    content: '${({ emoji }) => emoji}';
    position: absolute;
    left: 6px;
  }
`

export const Value = styled.span<{
  striked?: boolean
  highlight?: boolean
  good?: boolean
  bad?: boolean
}>`
  text-decoration: ${({ striked }) => (striked ? 'line-through' : 'inherit')};
  ${({ good }) =>
    !!good &&
    css`
      color: ${colors.green};
    `}
  ${({ bad }) =>
    !!bad &&
    css`
      color: ${colors.red};
    `}

  ${({ highlight }) =>
    !!highlight &&
    css`
      font-weight: 600;
      background-color: #fffdd5;
      border: 1px solid #f9f34c;
      padding: 0 2px;
      border-radius: 2px;
    `}
`

export const Paragraph = styled.p`
  white-space: pre-line;
`

export const Gutter = styled.div`
  width: 8px;
`

export const CriteriaEditorFieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  & > div {
    flex: 1;
  }
`
