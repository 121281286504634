import memoize from 'lodash/memoize'
import uniq from 'lodash/uniq'
import { DateTime } from 'luxon'

import { normalizeTimeZone } from '../../../../components/TimeZoneSelect'
import { Skillset } from '../../../../components/skillset/utils'
import {
  CefrScale,
  isAssistantable,
  MatcherAssistantFragment,
  onlyIfAssistantable,
  MatchingProfileFragment,
  Matching_FullFragment,
  MatchingProposalReply,
} from '../../../../graphql'

import {
  Criteria,
  Settings,
  Filter,
  MatcherAssistant,
  NumbersRange,
} from './types'

const WEEK_IN_MS = 7 * 24 * 3_600_000

export function getStartDateScore(date: string): number {
  if (!date) {
    return 9999
  }
  const d = new Date(date)
  return (d.getUTCFullYear() - 2000) * 100 + d.getMonth()
}

export const DEFAULT_SETTINGS: Settings = Object.freeze({
  matchedTimeZonePoints: 300,
  otherTimeZonePoints: 100,
  timeZoneTolerance: 1,
  averageWeeklyHoursPoints: 20,
  averageWeeklyHoursThreshold: 13,
  hoursNeededPoints: 50,
  recentOnboardingWeeksThreshold: 5,
  recentOnboardingPoints: -100,
  appliedToLeadPoints: 100,
  industryPoints: 20,
  toolPoints: 20,
  personalityPoints: 20,
  skillPoints: 20,
  languagePoints: 20,
  englishLevelPoints: 20,
  openToPoints: 50,
  skillsetMalusThreshold: 5,
  skillsetMalusPoints: -20,
  skillsetBonusThreshold: 8,
  skillsetBonusPoints: 20,
})

export const DEFAULT_CRITERIA: Criteria = {
  industries: [],
  languages: [],
  englishLevel: null,
  tools: [],
  personalityTraits: [],
  skillset: [],
  timeZone: null,
  isMatch: true,
  isSupport: false,
  showSandboxUsers: false,
}

export const DEFAULT_FILTER: Filter = {}

const joinStringsWithCommaAnd = ([...texts]: string[]): string => {
  if (texts.length < 2) {
    return texts[0] || ''
  }

  const last = texts.pop()
  return `${texts.join(', ')} and ${last}`
}

export const getTimeZonesDiff = memoize(
  function (
    tz1: string | undefined | null,
    tz2: string | undefined | null,
  ): number {
    if (!tz1 || !tz2) {
      return 24
    }

    if (tz1 === tz2) {
      return 0
    }

    const now = DateTime.local()
    const d1 = now.setZone(tz1, { keepLocalTime: true })
    const d2 = now.setZone(tz2, { keepLocalTime: true })

    return Math.abs((d1.valueOf() - d2.valueOf()) / 1000 / 3600)
  },
  (...tzs) => tzs.sort().join(''),
)

export const getHoursRange = (
  text: string | null | undefined,
): null | NumbersRange => {
  const matches = (text || '').match(/\d+/g)

  if (matches?.length) {
    return {
      from: parseInt(matches[0]),
      to: parseInt(matches.pop()!),
    }
  }

  return null
}

export const getAverageWeeklyHoursRange = (
  _assistant: MatcherAssistant,
): NumbersRange => {
  const assistant = onlyIfAssistantable(_assistant)
  if (!assistant) {
    return { from: 0, to: 0 }
  }

  const range: NumbersRange = {
    from: assistant?.averageWeeklyWorkedHours || 0,
    to: assistant?.averageWeeklyWorkedHours || 0,
  }

  assistant.workspaces.forEach((workspace) => {
    const usageStartDate = workspace.usage.startDate
    const execStartDate = workspace.executives[0]?.startDate

    if (
      !workspace.onboarding?.isCompleted ||
      !usageStartDate ||
      DateTime.fromISO(usageStartDate).toMillis() >
        Date.now() - 2 * WEEK_IN_MS ||
      !execStartDate ||
      DateTime.fromISO(execStartDate).toMillis() > Date.now() - 2 * WEEK_IN_MS
    ) {
      // New exec, so take the hours declared
      const weeklyHours = workspace.executives[0]?.currentPricing?.pricing
        .modelConfig.baseHours
        ? workspace.executives[0]?.currentPricing?.pricing.modelConfig
            .baseHours / 4
        : 0
      range.from += weeklyHours / 2
      range.to += weeklyHours
    }
  })

  return {
    from: Math.round(range.from * 100) / 100,
    to: Math.round(range.to * 100) / 100,
  }
}

export function getCriteriaFromMatching(
  matching: Matching_FullFragment,
  skillset: Skillset,
): Criteria {
  const [base, ...others] = matching.profiles.map((profile) =>
    getCriteriaFromLead(profile, skillset),
  )

  return others.reduce<Criteria>(
    (criteria, otherCriteria) => {
      return {
        matching,
        lead: null,
        assistant: null,
        isMatch: criteria.isMatch || otherCriteria.isMatch,
        isSupport: criteria.isSupport || otherCriteria.isSupport,
        timeZone: criteria.timeZone || otherCriteria.timeZone,
        showSandboxUsers:
          criteria.showSandboxUsers || otherCriteria.showSandboxUsers,
        hoursRange: !criteria.hoursRange
          ? otherCriteria.hoursRange
          : !otherCriteria.hoursRange
          ? criteria.hoursRange
          : {
              from: criteria.hoursRange.from + otherCriteria.hoursRange.from,
              to: criteria.hoursRange.to + otherCriteria.hoursRange.to,
            },
        skillset: [
          ...new Set([
            ...(criteria.skillset || []),
            ...(otherCriteria.skillset || []),
          ]),
        ],
        tools: [
          ...new Set([
            ...(criteria.tools || []),
            ...(otherCriteria.tools || []),
          ]),
        ],
        industries: [
          ...new Set([
            ...(criteria.industries || []),
            ...(otherCriteria.industries || []),
          ]),
        ],
        personalityTraits: [
          ...new Set([
            ...(criteria.personalityTraits || []),
            ...(otherCriteria.personalityTraits || []),
          ]),
        ],
      }
    },
    {
      ...base,
      matching,
    },
  )
}

export function getCriteriaFromLead(
  profile: MatchingProfileFragment,
  skillset: Skillset,
): Criteria {
  return {
    assistant: null,
    isMatch: true,
    isSupport: false,
    timeZone: normalizeTimeZone(profile?.country, true),
    showSandboxUsers: false,
    hoursRange: profile?.hoursNeededRange
      ? {
          from: profile?.hoursNeededRange.from,
          to: profile?.hoursNeededRange.to || profile?.hoursNeededRange.from,
        }
      : null,
    skillset:
      skillset?.skills
        .filter(({ label }) =>
          profile?.tasks.some(
            (task) => task.trim().toLowerCase() === label.trim().toLowerCase(),
          ),
        )
        .map(({ id }) => id) || null,
    tools: profile?.tools || [],
    industries: profile?.companyIndustry ? [profile.companyIndustry] : null,
    personalityTraits: profile?.eaPersonalityTraits || [],
  }
}

export function isLanguageInCriteria(
  language: string,
  criteria: Criteria,
): boolean {
  return !!criteria.languages?.includes(language)
}

export function isEnglishLevelInCriteria(
  englishLevel: CefrScale | null | undefined,
  criteria: Criteria,
): boolean {
  return (
    !!criteria.englishLevel &&
    !!englishLevel &&
    criteria.englishLevel <= englishLevel
  )
}

export function isIndustryInCriteria(
  industry: string,
  criteria: Criteria,
): boolean {
  return !!criteria.industries?.includes(industry)
}

export function isToolInCriteria(tool: string, criteria: Criteria): boolean {
  return !!criteria.tools?.includes(tool)
}

export function isPersonalityInCriteria(
  trait: string,
  criteria: Criteria,
): boolean {
  return !!criteria.personalityTraits?.includes(trait)
}

export function isBonusSkillsetRating(
  rating: number | undefined,
  settings: Settings,
): boolean {
  return Boolean(rating && rating >= settings.skillsetBonusThreshold)
}

export function isMalusSkillsetRating(
  rating: number | undefined,
  settings: Settings,
): boolean {
  return Boolean(rating && rating <= settings.skillsetMalusThreshold)
}

export function isAverageWeeklyHoursUnderThreshold(
  averageWeeklyHoursRange: NumbersRange,
  settings: Settings,
): boolean {
  return averageWeeklyHoursRange.to < settings.averageWeeklyHoursThreshold
}

export function isHoursNeededInCriteria(
  desiredHours: string | null | undefined,
  averageWeeklyHoursRange: NumbersRange,
  criteria: Criteria,
): boolean {
  const execRange = criteria.hoursRange
  const assistantRange = getHoursRange(desiredHours)

  if (execRange && assistantRange) {
    return assistantRange.to - averageWeeklyHoursRange.to >= execRange.to
  }

  return false
}

export function isLatestOnboardingRecent(
  latestOnboardingAt: string | Date | undefined | null,
  settings: Settings,
): boolean {
  if (!latestOnboardingAt) {
    return false
  }

  const diff = Date.now() - new Date(latestOnboardingAt).getTime()
  return diff <= settings.recentOnboardingWeeksThreshold * WEEK_IN_MS
}

export function didApply(assistantId: string, criteria: Criteria): boolean {
  return Boolean(
    criteria.matching?.applications.some(
      ({ assistant }) => assistant.id === assistantId,
    ),
  )
}

export function didDecline(assistantId: string, criteria: Criteria): boolean {
  return Boolean(
    criteria.matching?.proposals.some(
      ({ reply, assistant }) =>
        assistant.id == assistantId && reply === MatchingProposalReply.NO,
    ),
  )
}

export function getAssistantTimeZones(assistant: MatcherAssistant): {
  current: string[]
  other: string[]
  all: string[]
} {
  const tzs: { current: string[]; other: string[] } = { current: [], other: [] }

  if (isAssistantable(assistant)) {
    assistant.workspaces.forEach(({ workingHoursTimeZone: tz }) => {
      if (tz && !tzs.current.includes(tz)) {
        tzs.current.push(tz)
      }
    })

    assistant.workTimeZones?.forEach((tz) => {
      if (tz && !tzs.current.includes(tz) && !tzs.other.includes(tz)) {
        tzs.other.push(tz)
      }
    })

    const tz = assistant.city?.timeZone
    if (tz && !tzs.current.includes(tz) && !tzs.other.includes(tz)) {
      tzs.other.push(tz)
    }
  }

  return { ...tzs, all: [...tzs.current, ...tzs.other] }
}

export function getAssistantScore(
  assistant: MatcherAssistant,
  criteria: Criteria,
  settings: Settings,
): number {
  if (!isAssistantable(assistant)) {
    return 0
  }

  let score = 0

  if (criteria.isMatch && assistant.isOpenToMatch) {
    score += settings.openToPoints
  }
  if (criteria.isSupport && assistant.isOpenToSupport) {
    score += settings.openToPoints
  }

  // Languages
  if (criteria.languages?.length && assistant.languages.length) {
    for (const lang of assistant.languages) {
      if (isLanguageInCriteria(lang, criteria)) {
        score += settings.languagePoints || 0
      }
    }
  }

  if (isEnglishLevelInCriteria(assistant.englishLevel, criteria)) {
    score += settings.englishLevelPoints || 0
  }

  // Industries
  const assistantIndustries = uniq([
    ...(assistant.industries || []),
    ...(assistant.interestedInIndustries || []),
  ])
  if (criteria.industries?.length && assistantIndustries.length) {
    for (const industry of assistantIndustries) {
      if (isIndustryInCriteria(industry, criteria)) {
        score += settings.industryPoints || 0
      }
    }
  }

  // Tools
  if (criteria.tools?.length && assistant.experienceInTools.length) {
    for (const tool of assistant.experienceInTools) {
      if (isToolInCriteria(tool, criteria)) {
        score += settings.toolPoints || 0
      }
    }
  }

  // Personality
  if (
    criteria.personalityTraits?.length &&
    assistant.personalityTraits.length
  ) {
    for (const trait of assistant.personalityTraits) {
      if (isPersonalityInCriteria(trait, criteria)) {
        score += settings.personalityPoints || 0
      }
    }
  }

  // Skillset
  if (criteria.skillset?.length) {
    for (const skillsetId of criteria.skillset) {
      if (
        isBonusSkillsetRating(assistant.skillsetRating?.[skillsetId], settings)
      ) {
        score += settings.skillsetBonusPoints
      } else if (
        isMalusSkillsetRating(assistant.skillsetRating?.[skillsetId], settings)
      ) {
        score += settings.skillsetMalusPoints
      }
    }
  }

  // Timezone
  // https://double.height.app/T-2205
  const timeZones = getAssistantTimeZones(assistant)
  let didCountTz = false

  for (const timeZone of timeZones.current) {
    const tzDiff = getTimeZonesDiff(timeZone, criteria.timeZone)
    // strict compare, no tolerance
    if (!didCountTz && tzDiff < 1) {
      score += settings.matchedTimeZonePoints
      didCountTz = true
      break // don't count them more than once
    }
  }

  if (!didCountTz) {
    // check for all TZ because we apply some tolerance here
    for (const timeZone of timeZones.all) {
      const tzDiff = getTimeZonesDiff(timeZone, criteria.timeZone)
      if (!didCountTz && tzDiff <= settings.timeZoneTolerance) {
        score += settings.otherTimeZonePoints
        didCountTz = true
        break // don't count them more than once
      }
    }
  }

  const averageWeeklyHoursRange = getAverageWeeklyHoursRange(assistant)

  // Average weekly hours
  if (isAverageWeeklyHoursUnderThreshold(averageWeeklyHoursRange, settings)) {
    score += settings.averageWeeklyHoursPoints
  }

  // Hours needed
  if (
    isHoursNeededInCriteria(
      assistant.targetTotalWeeklyHours,
      averageWeeklyHoursRange,
      criteria,
    )
  ) {
    score += settings.hoursNeededPoints
  }

  // Recent onboarding
  if (isLatestOnboardingRecent(assistant.latestOnboardingAt, settings)) {
    score += settings.recentOnboardingPoints
  }

  // Bonus is assistant applied to lead
  if (didApply(assistant.id, criteria)) {
    score += settings.appliedToLeadPoints
  }

  return score
}

// https://double.height.app/T-3600
export function getReasonsForMatch(
  assistant: MatcherAssistant,
  criteria: Omit<Criteria, 'lead'>,
  settings: Settings,
  skillNameById: (skillsetId: string) => string | undefined,
): string {
  if (!isAssistantable(assistant)) {
    return ''
  }

  const reasons: string[] = []

  // Skillset

  if (criteria.skillset?.length) {
    const matchedSkillset = criteria.skillset.filter((skillsetId) =>
      isBonusSkillsetRating(assistant.skillsetRating?.[skillsetId], settings),
    )

    if (matchedSkillset.length) {
      reasons.push(
        `Strong skillset to support with ${joinStringsWithCommaAnd(
          matchedSkillset.map((id) => skillNameById(id) || id),
        )}`,
      )
    }
  }

  // Industries

  const assistantIndustries = uniq([
    ...(assistant.industries || []),
    ...(assistant.interestedInIndustries || []),
  ])

  if (criteria.industries?.length && assistantIndustries.length) {
    const matchedIndustries = assistantIndustries.filter((industry) =>
      isIndustryInCriteria(industry, criteria),
    )

    if (matchedIndustries.length) {
      reasons.push(
        `Experience/interest in ${joinStringsWithCommaAnd(matchedIndustries)}`,
      )
    }
  }

  // Tools

  if (criteria.tools?.length && assistant.experienceInTools.length) {
    const matchedTools = assistant.experienceInTools.filter((tool) =>
      isToolInCriteria(tool, criteria),
    )

    if (matchedTools.length) {
      reasons.push(`Experience using ${joinStringsWithCommaAnd(matchedTools)}`)
    }
  }

  // Personality
  if (
    criteria.personalityTraits?.length &&
    assistant.personalityTraits.length
  ) {
    const matchedTraits = assistant.personalityTraits.filter((trait) =>
      isPersonalityInCriteria(trait, criteria),
    )

    if (matchedTraits.length) {
      reasons.push(
        `Good working style fit: ${joinStringsWithCommaAnd(matchedTraits)}`,
      )
    }
  }

  return reasons.map((reason) => `- ${reason}`).join('\n')
}

// Smaller the score, earlier in the list
export function getAssistantOrder(
  assistant: MatcherAssistantFragment,
  criteria: Criteria,
  settings: Settings,
): number {
  if (!isAssistantable(assistant)) {
    return 0
  }

  let score = getAssistantScore(assistant, criteria, settings) * -100_000

  score -= getStartDateScore(assistant.startDate!)

  if (assistant.isOpenToMatch) {
    score -= 10_000 * (criteria.isMatch ? 2 : 1)
  }
  if (assistant.isOpenToSupport) {
    score -= 10_000 * (criteria.isSupport ? 2 : 1)
  }

  if (didApply(assistant.id, criteria)) {
    score -= 100_000_000
  }

  if (didDecline(assistant.id, criteria)) {
    score -= 100_000_000
  }

  return score
}

export const makeAssistantFilter =
  (filter: Filter) =>
  (assistant: MatcherAssistantFragment): boolean => {
    if (filter.search) {
      const keyword = filter.search.toLowerCase().trim()
      const isMatch = assistant.profile.displayName
        ?.toLowerCase()
        .includes(keyword)
      if (!isMatch) {
        return false
      }
    }

    return true
  }
