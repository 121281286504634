import { ApolloError } from '@apollo/client'
import { useCallback } from 'react'

import {
  useCreateStripeInvoiceMutation,
  useVoidStripeInvoiceMutation,
  usePayStripeInvoiceMutation,
  useSendStripeInvoiceMutation,
  PayStripeInvoiceMutationVariables,
  StripeCustomerPaymentStatus,
  StripeInvoiceStatus,
} from '../../../../graphql'
import {
  ReportSummary,
  getStripeInvoiceLineItems,
  ReportsQuery,
  InvoiceEdit,
} from '../utils'

type BillingVariables = Omit<PayStripeInvoiceMutationVariables, 'input'>

type Return = [
  {
    createInvoice: (
      report: ReportSummary,
      edit: InvoiceEdit | null,
      forceCreation?: boolean,
    ) => Promise<unknown>
    payInvoice: (
      report: ReportSummary,
      edit: InvoiceEdit | null,
    ) => Promise<unknown>
    voidInvoice: (report: ReportSummary) => Promise<unknown>
    sendInvoice: (report: ReportSummary) => Promise<unknown>
    payAndSendInvoice: (
      report: ReportSummary,
      edit: InvoiceEdit | null,
    ) => Promise<unknown>
  },
  {
    loading: boolean
    error: ApolloError | undefined
  },
]

const getCustomer = (report: ReportSummary) => {
  return report.invoicedUser.stripeCustomer
}

const getCollectionMethod = (report: ReportSummary) => {
  return report.invoicedUser.cockpitInvoicingCollectionMethod
}

const getInvoice = (report: ReportSummary) => {
  const stripeCustomer = getCustomer(report)
  const invoice = stripeCustomer?.invoices?.[0]
  return invoice
}

const useReportInvoiceActions = (
  query: ReportsQuery,
  billingVariables: BillingVariables,
): Return => {
  const [createStripeInvoice, createInvoiceResult] =
    useCreateStripeInvoiceMutation()
  const [payStripeInvoice, payInvoiceResult] = usePayStripeInvoiceMutation()
  const [sendStripeInvoice, sendInvoiceResult] = useSendStripeInvoiceMutation()
  const [voidStripeInvoice, voidInvoiceResult] = useVoidStripeInvoiceMutation()

  const error =
    createInvoiceResult.error ||
    payInvoiceResult.error ||
    sendInvoiceResult.error ||
    voidInvoiceResult.error
  const loading =
    createInvoiceResult.loading ||
    payInvoiceResult.loading ||
    sendInvoiceResult.loading ||
    voidInvoiceResult.loading

  const createInvoice = useCallback(
    async (
      report: ReportSummary,
      edit: InvoiceEdit | null,
      forceCreation = false,
    ) => {
      const stripeCustomer = getCustomer(report)
      const collectionMethod = getCollectionMethod(report)

      if (!stripeCustomer) {
        return
      }

      if (!forceCreation) {
        const invoice = getInvoice(report)
        if (invoice) {
          return
        }
      }

      return await createStripeInvoice({
        variables: {
          ...billingVariables,
          input: {
            customerId: stripeCustomer.id,
            autoCharge:
              stripeCustomer?.paymentStatus ===
                StripeCustomerPaymentStatus.CARD &&
              collectionMethod === 'CHARGE_DEFAULT_CARD',
            lineItems: getStripeInvoiceLineItems(query, report, edit),
          },
        },
      })
    },
    [createStripeInvoice, billingVariables, query],
  )

  const voidInvoice = useCallback(
    async (report: ReportSummary) => {
      const invoice = getInvoice(report)
      if (!invoice) {
        return
      }

      if (
        [
          StripeInvoiceStatus.PAID,
          StripeInvoiceStatus.VOID,
          StripeInvoiceStatus.DELETED,
        ].includes(invoice.status)
      ) {
        return
      }

      return await voidStripeInvoice({
        variables: {
          ...billingVariables,
          input: {
            invoiceId: invoice.id,
          },
        },
      })
    },
    [billingVariables, voidStripeInvoice],
  )

  const sendInvoice = useCallback(
    async (report: ReportSummary) => {
      const stripeCustomer = getCustomer(report)
      const invoice = getInvoice(report)
      const collectionMethod = getCollectionMethod(report)
      if (!invoice || !stripeCustomer) {
        return
      }

      if (
        stripeCustomer.paymentStatus === StripeCustomerPaymentStatus.CARD &&
        collectionMethod !== 'SEND_INVOICE'
      ) {
        return
      }

      if (invoice.status !== StripeInvoiceStatus.DRAFT) {
        return
      }

      return await sendStripeInvoice({
        variables: {
          ...billingVariables,
          input: {
            invoiceId: invoice.id,
          },
        },
      })
    },
    [billingVariables, sendStripeInvoice],
  )

  const payInvoice = useCallback(
    async (report: ReportSummary, edit: InvoiceEdit | null) => {
      const stripeCustomer = getCustomer(report)
      const invoice = getInvoice(report)
      const collectionMethod = getCollectionMethod(report)
      if (!invoice || !stripeCustomer) {
        return
      }

      if (collectionMethod !== 'CHARGE_DEFAULT_CARD') {
        return
      }

      if (stripeCustomer.paymentStatus !== StripeCustomerPaymentStatus.CARD) {
        return
      }

      if (invoice.status !== StripeInvoiceStatus.DRAFT) {
        return
      }

      const payResult = await payStripeInvoice({
        variables: {
          ...billingVariables,
          input: {
            invoiceId: invoice.id,
          },
        },
      })

      if (!payResult.data?.payStripeInvoice.isSuccess) {
        // Void invoice that failed to be charged
        await voidStripeInvoice({
          variables: {
            ...billingVariables,
            input: {
              invoiceId: invoice.id,
            },
          },
        })

        // Create new invoice
        const createResult = await createStripeInvoice({
          variables: {
            ...billingVariables,
            input: {
              customerId: stripeCustomer.id,
              autoCharge: false,
              lineItems: getStripeInvoiceLineItems(query, report, edit),
            },
          },
        })

        // Send new invoice
        const newInvoice = createResult.data?.createStripeInvoice.invoice
        if (newInvoice) {
          await sendStripeInvoice({
            variables: {
              ...billingVariables,
              input: {
                invoiceId: newInvoice.id,
              },
            },
          })
        }
      }

      return payResult
    },
    [
      billingVariables,
      createStripeInvoice,
      payStripeInvoice,
      query,
      sendStripeInvoice,
      voidStripeInvoice,
    ],
  )

  const payAndSendInvoice = useCallback(
    async (report: ReportSummary, edit: InvoiceEdit | null) => {
      await payInvoice(report, edit)
      await sendInvoice(report)
    },
    [payInvoice, sendInvoice],
  )

  return [
    {
      createInvoice,
      voidInvoice,
      payInvoice,
      sendInvoice,
      payAndSendInvoice,
    },
    {
      error,
      loading,
    },
  ]
}

export default useReportInvoiceActions
