import { ExecutivableBillingFragment } from '../../graphql'
import { formatDate, DateType } from '../utils'

import { EntityType } from './types'

export const isUser = (
  entity?: EntityType,
): entity is ExecutivableBillingFragment =>
  !!entity && entity?.__typename !== 'Team'

export const formatCycleDates = (
  startDate?: string | Date | null,
  endDate?: string | Date | null,
) => {
  return `${formatDate(startDate, DateType.LONG)} - ${formatDate(
    endDate,
    DateType.LONG,
  )}`
}
