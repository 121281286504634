import { Maybe } from '../../../../graphql'
import getCurrencySymbol from '../../../../lib/getCurrencySymbol'

export function getFlagEmoji(countryCode: string) {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)))
}

export const getAssistantCountryRateCurrency = (
  country?: Maybe<string>,
  currency?: Maybe<string>,
  rate?: Maybe<number>,
) => {
  const currencySymbol = getCurrencySymbol(currency || '')

  const flag = country ? getFlagEmoji(country) : ''

  return `${flag} ${currencySymbol}${rate}/hr ${currency}`
}
